<template>
  <section class="interviews-new-sidebar">
    <header class="interviews-new-sidebar__header">
      <figure class="interviews-new-sidebar__photo">
        <img
          v-if="candidate.avatar"
          class="interviews-new-sidebar__photo__image"
          :src="candidate.avatar"
        >
      </figure>
      <section class="interviews-new-sidebar__header-text">
        <p
          class="interviews-new-sidebar__label"
          data-test="sidebar-scheduled-with"
        >
          {{ $t(`${langPath}.scheduledWith`) }}
        </p>
        <h6 class="interviews-new-sidebar__title">
          {{ candidate.name }}
        </h6>
      </section>
    </header>

    <section
      v-if="applicant.position"
      class="interviews-new-sidebar__position"
    >
      <p class="interviews-new-sidebar__position-label">
        {{ $t(`${langPath}.participate`) }}
      </p>
      <router-link
        class="interviews-new-sidebar__position-name"
        :to="workflowRoute"
      >
        {{ applicant.position.title }}
      </router-link>
    </section>

    <div class="interviews-new-sidebar__line" />

    <app-date-picker
      class="interviews-new-sidebar--margin-top-6"
      color="primary"
      :value="date"
      no-shadow
      no-title
      dark
      @input="$emit('update:date', $event)"
    />

    <div class="interviews-new-sidebar__line" />

    <section class="interviews-new-sidebar--margin-top-6">
      <h6
        v-if="hasCalendars"
        class="interviews-new-sidebar__title"
        data-test="sidebar-my-calendars"
      >
        {{ $t(`${langPath}.myCalendars`) }}
      </h6>

      <section v-if="externalCalendars.length">
        <app-checkbox
          v-for="calendar in externalCalendars"
          :key="calendar.calendarName"
          class="interviews-new-sidebar__calendars-list__item"
          :name="`calendar-${calendar.name}`"
          :label="calendar.calendarName"
          :value="calendar.selected"
          @input="handleCheckCalendar(calendar.calendarId, $event)"
        />
      </section>

      <ev-button
        v-if="!hasCalendars"
        full-width
        data-test="sidebar-connect-calendars-button"
        variant="primary"
        class="interviews-new-sidebar--margin-top-4"
        @click="handleSync"
      >
        {{ $t(`${langPath}.connectCalendarsButton`) }}
      </ev-button>

      <ev-button
        v-else
        full-width
        data-test="sidebar-disconnect-calendars-button"
        variant="primary"
        class="interviews-new-sidebar--margin-top-4"
        @click="handleDisconnect"
      >
        {{ $t(`${langPath}.disconnectconnectCalendarsButton`) }}
      </ev-button>
    </section>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { APPLICANTS, CURRENT_USER, EXTERNAL_CALENDARS } from '@/store/namespaces'
import repository from '@/repository/external-calendars'
import { TheNotificationCardList } from '@revelotech/everest'
import EvButton from '@revelotech/everestV2/EvButton'

const applicantsHelper = createNamespacedHelpers(APPLICANTS)
const currentUserHelper = createNamespacedHelpers(CURRENT_USER)
const externalCalendarsHelper = createNamespacedHelpers(EXTERNAL_CALENDARS)

export default {
  name: 'InterviewsNewSidebar',

  components: {
    EvButton
  },

  props: {
    applicantId: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath
    }
  },

  computed: {
    ...applicantsHelper.mapState(['applicant']),
    ...currentUserHelper.mapState(['currentUser']),
    ...externalCalendarsHelper.mapState(['externalCalendars']),

    candidate () {
      return this.applicant?.candidate
    },

    hasCalendars () {
      return Boolean(this.externalCalendars?.length)
    },

    workflowRoute () {
      return {
        name: 'WorkflowBoard',
        params: { positionId: this.applicant?.position?.id }
      }
    }
  },

  created () {
    this.getApplicant(this.applicantId)
  },

  methods: {
    ...applicantsHelper.mapActions(['getApplicant']),

    ...externalCalendarsHelper.mapActions(
      [
        'getExternalCalendars',
        'setSelectedCalendars'
      ]
    ),

    async handleDisconnect () {
      try {
        await repository.disconnect()

        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.disconnectSuccess`),
          modifier: 'success'
        })
      } catch {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.disconnectError`),
          modifier: 'error'
        })
      }

      await this.getExternalCalendars()
    },

    handleSync () {
      if (this.hasCalendars) return

      repository.getAuthUrl()
    },

    handleCheckCalendar (calendarId, isChecked) {
      const calendars = this.externalCalendars.map((calendar) => ({
        calendarId: calendar.calendarId,
        selected: calendar.calendarId === calendarId ? isChecked : calendar.selected
      }))

      this.setSelectedCalendars({ date: this.date, calendars })
    }
  }
}
</script>

<style lang="scss">
.interviews-new-sidebar {
  padding: 4*$base;

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  &__header-text {
    @include margin(left, 3);
  }

  &__position {
    margin-top: 3*$base;

    &-label {
      @extend %caption;

      color: $gray40;
      display: block;
    }

    &-name {
      color: $white;
      display: block;
      font-weight: bold;
      margin-top: 2*$base;

      &:hover { color: $blue-light; }
    }
  }

  &__photo {
    flex-shrink: 0;
    height: 48px;
    width: 48px;

    &__image {
      border: 2px solid $white;
      border-radius: 50%;
      box-shadow: $shadow-8;
      height: 100%;
      max-width: 100%;
    }
  }

  &__label {
    @extend %caption;

    color: $gray40;
  }

  &__title {
    @extend %subtitle1;

    color: $white;
  }

  &__checkbox {
    @extend %caption;

    color: $white;
  }

  &__line {
    @include margin(top, 6);

    border-bottom: solid 1px $gray80;
  }

  &--margin-top-4 {
    @include margin(top, 4);
  }

  &--margin-top-6 {
    @include margin(top, 6);
  }

  &__calendars-list {
    &__item {
      color: $white;

      .checkbox__label {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 95%;
      }
    }
  }
}
</style>
