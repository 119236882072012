<template>
  <app-modal
    class="interviews-new-schedule-modal"
    scroll
    @close="$emit('close')"
  >
    <template slot="header">
      <h6 class="interviews-new-schedule-modal__header">
        {{ scheduleInterview }}
      </h6>
    </template>
    <section class="interviews-new-schedule-modal__body">
      <section class="interviews-new-schedule-modal__body-input">
        <app-date-picker-dialog
          v-model="draftInterview.date"
          v-validate="'required'"
          input-class="interviews-new-schedule-modal--width-50"
          data-test="date-picker-interview-date"
          :name="$t(`${langPath}.dateLabel`)"
          :label="$t(`${langPath}.dateLabel`)"
          :picker-options="pickerOptions.default"
          :placeholder="$t(`${langPath}.datePlaceholder`)"
        />
        <app-time-picker-dialog
          v-model="draftInterview.startTime"
          v-validate="'required'"
          input-class="interviews-new-schedule-modal--width-25"
          data-test="picker-start-time"
          :name="$t(`${langPath}.fromLabel`)"
          :label="$t(`${langPath}.fromLabel`)"
          :picker-options="pickerOptions.startDate"
          :placeholder="$t(`${langPath}.fromPlaceholder`)"
          @input="handleStartTimeChange"
        />
        <app-time-picker-dialog
          v-model="draftInterview.endTime"
          v-validate="'required'"
          input-class="interviews-new-schedule-modal--width-25"
          data-test="picker-end-time"
          :name="$t(`${langPath}.toLabel`)"
          :label="$t(`${langPath}.toLabel`)"
          :picker-options="pickerOptions.endDate"
          :placeholder="$t(`${langPath}.toPlaceholder`)"
        />
      </section>
      <section class="interviews-new-schedule-modal__body-select">
        <label
          class="interviews-new-schedule-modal__body-label"
          data-test="schedule-modal-interview-type-label"
        >
          {{ $t(`${langPath}.interviewTypeLabel`) }}
        </label>
        <app-select
          v-model="draftInterview.mediumGroup"
          class="interviews-new-schedule-modal__body-select"
          name="type"
          data-test="medium-group-input"
          :options="mediumGroups"
          @input="handleMediumGroup"
        />
        <app-select
          v-if="hasMediumToBeDisplayed"
          v-model="draftInterview.medium"
          class="interviews-new-schedule-modal__body-select"
          name="medium"
          data-test="medium-input"
          :options="mediums"
          @input="handleMedium"
        />
      </section>
      <section
        class="interviews-new-schedule-modal__body-input"
        data-test="medium-info"
      >
        <app-text-mask
          v-if="displayMasked"
          v-model="draftInterview.mediumInfo"
          name="mediumInfo"
          :label="$t(`${langPath}.mediumOptions.${mediumKey}.info.label`)"
          :placeholder="mediumInfoPlaceholder"
          :mask="phoneMask"
        />
        <app-text-input
          v-else
          v-model="draftInterview.mediumInfo"
          name="mediumInfo"
          data-test="medium-info-input"
          :label="$t(`${langPath}.mediumOptions.${mediumKey}.info.label`)"
          :placeholder="mediumInfoPlaceholder"
        />
      </section>
      <section class="interviews-new-schedule-modal__body-input">
        <app-text-input
          v-model="draftInterview.invitedEmails"
          v-validate="'emails_rules'"
          data-test="input-emails"
          :name="$t(`${langPath}.inviteColleaguesLabel`)"
          :label="$t(`${langPath}.inviteColleaguesLabel`)"
          :placeholder="$t(`${langPath}.inviteColleaguesPlaceholder`)"
        />
        <p
          class="interviews-new-schedule-modal__body-colleagues-text"
          data-test="schedule-modal-invite-colleagues"
        >
          {{ $t(`${langPath}.inviteColleaguesText`) }}
        </p>
      </section>
    </section>
    <template slot="footer">
      <section class="interviews-new-schedule-modal__footer">
        <ev-button
          class="interviews-new-schedule-modal--margin-right-4"
          data-test="cancel-form"
          variant="tertiary"
          color="blue"
          @click="$emit('close')"
        >
          {{ $t(`${langPath}.prevButton`) }}
        </ev-button>
        <ev-button
          data-test="send-form"
          :disabled="isLoading"
          @click="sendForm"
        >
          {{ scheduleInterview }}
        </ev-button>
      </section>
    </template>
  </app-modal>
</template>

<script>
import PICKER_OPTIONS from '@/helpers/constants/picker-options.js'
import PHONE_MASKS from '@/helpers/constants/phone-masks.js'
import convertDate from '@/mixins/convert-date.js'
import get from 'lodash/get'
import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER, APPLICANTS, INTERVIEWS }
  from '@/store/namespaces'
import { TheNotificationCardList } from '@revelotech/everest'
import EvButton from '@revelotech/everestV2/EvButton'

const applicantsHelper = createNamespacedHelpers(APPLICANTS)
const currentUserHelper = createNamespacedHelpers(CURRENT_USER)
const interviewsHelper = createNamespacedHelpers(INTERVIEWS)
const OTHER_MEDIUMS = [
  'other',
  'phone'
]
const REMOTE_MEDIUM_GROUPS = ['otherMediums']
const MEDIUM_GROUPS = [...REMOTE_MEDIUM_GROUPS, 'in_person']
const MIN_MINUTES_RANGE = 30

export default {
  name: 'InterviewsNewScheduleModal',
  components: {
    EvButton
  },
  mixins: [convertDate],
  props: {
    interview: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath,
      isLoading: false,
      draftInterview: {
        id: this.interview.id,
        mediumGroup: this.initialMediumGroup(),
        medium: this.initialMedium(),
        mediumInfo: this.initialMediumInfo(),
        invitedEmails: this.interview.invitedEmails?.join(','),
        date: this.toIsoDate(this.interview.scheduledStartAt),
        startTime: this.toHour(this.interview.scheduledStartAt),
        endTime: this.toHour(this.interview.scheduledEndAt)
      }
    }
  },
  computed: {
    ...applicantsHelper.mapState(['applicant']),
    ...currentUserHelper.mapState(['currentUser']),
    isUpdated () {
      return this.interview.id
    },
    companyAddress () {
      const { company } = this.currentUser
      return `${company.address}, ${company.location}`
    },
    pickerOptions () {
      return PICKER_OPTIONS
    },
    phoneMask () {
      return PHONE_MASKS
    },
    hasMediumToBeDisplayed () {
      return this.draftInterview.medium !== this.draftInterview.mediumGroup
    },
    displayMasked () {
      return this.draftInterview.medium === 'phone'
    },
    hasInvalidTime () {
      return this.hasStartSameOrAfterEndTime
    },
    hasStartSameOrAfterEndTime () {
      return this.startTimeInMinutes >= this.endTimeInMinutes
    },
    startTimeInMinutes () {
      return this.hourToMinutes(this.draftInterview.startTime)
    },
    endTimeInMinutes () {
      return this.hourToMinutes(this.draftInterview.endTime)
    },
    maxEndTimeInMinutes () {
      return this.hourToMinutes(this.pickerOptions.endDate.maxTime)
    },
    mediums () {
      return OTHER_MEDIUMS.map((medium) => {
        return {
          label: this.$t(`${this.langPath}.mediumOptions.${medium}.label`),
          value: medium
        }
      })
    },
    mediumGroups () {
      const groups = this.$route.query.remote_only
        ? REMOTE_MEDIUM_GROUPS
        : MEDIUM_GROUPS

      return groups.map((group) => {
        return {
          label: this.$t(`${this.langPath}.mediumOptions.${group}.label`),
          value: group
        }
      })
    },
    mediumKey () {
      return this.draftInterview.medium
    },
    scheduleInterview () {
      if (this.draftInterview.id) {
        return this.$t(`${this.langPath}.rescheduleInterview`)
      } else {
        return this.$t(`${this.langPath}.scheduleInterview`)
      }
    },
    mediumInfoPlaceholder () {
      return this.$t(
        `${this.langPath}.mediumOptions.${this.mediumKey}.info.placeholder`
      )
    }
  },
  watch: {
    currentUser: {
      handler () {
        this.handleMedium()
      },
      immediate: true
    },
    'draftInterview.startTime': {
      handler (newValue) {
        this.latestSelectedStartTime = newValue
      },
      immediate: true
    },
    'draftInterview.endTime': {
      handler (newValue) {
        this.latestSelectedEndTime = newValue
      },
      immediate: true
    }
  },
  methods: {
    ...applicantsHelper.mapActions(['createInterview']),
    ...interviewsHelper.mapActions(['updateInterview']),

    handleMediumGroup () {
      if (this.draftInterview.mediumGroup === 'otherMediums') {
        this.draftInterview.medium = 'other'
      } else {
        this.draftInterview.medium = this.draftInterview.mediumGroup
      }
      this.handleMedium()
    },

    handleMedium () {
      if (this.draftInterview.medium === 'other') {
        this.draftInterview.mediumInfo = ''
      } else if (this.draftInterview.medium === 'in_person') {
        this.draftInterview.mediumInfo = this.companyAddress
      }
    },

    handleStartTimeChange () {
      this.updateEndTimeBasedOnStartTime()
    },

    calculateLatestSelectedRangeInMinutes () {
      const latestStartTimeInMinutes =
        this.hourToMinutes(this.latestSelectedStartTime)
      const latestEndTimeInMinutes =
        this.hourToMinutes(this.latestSelectedEndTime)

      return Math.max(
        MIN_MINUTES_RANGE,
        latestEndTimeInMinutes - latestStartTimeInMinutes
      )
    },
    updateEndTimeBasedOnStartTime () {
      const endTimeBasedOnStartTime = Math.min(
        this.startTimeInMinutes + this.calculateLatestSelectedRangeInMinutes(),
        this.maxEndTimeInMinutes
      )

      this.draftInterview.endTime = this.minutesToHour(endTimeBasedOnStartTime)
    },
    initialMedium () {
      return this.interview.medium || 'other'
    },
    initialMediumInfo () {
      return this.interview.mediumInfo
    },
    initialMediumGroup () {
      if (MEDIUM_GROUPS.includes(this.interview.medium)) {
        return this.interview.medium
      }

      return 'otherMediums'
    },
    async sendForm () {
      this.isLoading = true
      await this.validateForm()

      if (this.$validator.errors.items.length) {
        this.isLoading = false
        return
      }
      await this.saveInterview()
      this.$emit('close')
      this.$emit('success')
      this.isLoading = false
    },
    async saveInterview () {
      const mediumInfo = this.draftInterview.mediumInfo
      const medium = this.draftInterview.medium

      const payload = {
        applicantId: this.applicant.id,
        interview: {
          employerId: this.currentUser.id,
          id: this.draftInterview.id,
          scheduledStartAt: this.toISODatetime(
            `${this.draftInterview.date} ${this.draftInterview.startTime}`
          ),
          scheduledEndAt: this.toISODatetime(
            `${this.draftInterview.date} ${this.draftInterview.endTime}`
          ),
          medium: medium,
          mediumInfo: mediumInfo,
          invitedEmails: this.draftInterview.invitedEmails?.split(',')
        }
      }
      let errorMessage = ''

      try {
        if (this.isUpdated) {
          errorMessage = this.$t(`${this.langPath}.errorUpdateMessage`)

          await this.updateInterview(payload)
        } else {
          errorMessage = this.$t(`${this.langPath}.errorCreateMessage`)

          await this.createInterview(payload)
        }

        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.successCreateMessage`),
          modifier: 'success'
        })
      } catch (error) {
        const scheduledStartAt = get(
          error,
          'response.data.errors_list.scheduled_time'
        )

        if (scheduledStartAt) {
          scheduledStartAt.forEach((message) => {
            TheNotificationCardList.createNotification({
              message: message,
              modifier: 'error'
            })
          })
        } else {
          TheNotificationCardList.createNotification({
            message: errorMessage,
            modifier: 'error'
          })
        }
      }
    },
    async validateForm () {
      await this.$validator.validateAll()

      if (this.hasInvalidTime) {
        this.errors.add({
          field: this.$t(`${this.langPath}.toLabel`),
          msg: this.$t('validation.messages._default', {
            0: this.$t(`${this.langPath}.toLabel`)
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
.interviews-new-schedule-modal {
  &__header {
    @extend %h6;
  }

  &__body {
    input::placeholder {
      color: $gray40;
    }

    &-input {
      @include margin(top, 4);

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
    }

    &-select {
      @include margin(top, 2);
    }

    &-label {
      @extend %body-text2;
      @extend %antenna;
      @extend %medium;
    }

    &-colleagues-text {
      @include margin(top, 2);

      @extend %caption;

      color: $gray80;
    }
  }

  &__footer {
    @include margin(top, 4);

    display: flex;
    justify-content: space-between;

    @include breakpoint(md) {
      justify-content: flex-end;
    }
  }

  &--margin-right-4 {
    @include margin(right, 4);
  }

  &--width-50 {
    width: 100%;

    @include breakpoint(md) {
      @include margin(right, 6);

      width: calc(50% - 24px);
    }
  }

  &--width-25 {
    @include margin(right, 6);

    width: calc(50% - 12px);

    @include breakpoint(md) {
      width: calc(25% - 12px);
    }

    &:last-child {
      @include margin(right, 0);
    }
  }
}
</style>
