<template>
  <app-modal @close="$emit('close')">
    <template slot="header">
      <h6
        class="interviews-new-unavailable-schedule-modal__header"
        data-test="unavailable-schedule-modal-title"
      >
        {{ $t(`${langPath}.title`) }}
      </h6>
    </template>
    <section class="interviews-new-unavailable-schedule-modal__body">
      <p
        class="interviews-new-unavailable-schedule-modal--margin-top-4"
        data-test="unavailable-schedule-modal-instructions"
      >
        {{ $t(`${langPath}.instructions`) }}
      </p>
    </section>
    <template slot="footer">
      <section class="interviews-new-unavailable-schedule-modal__footer">
        <ev-button
          class="interviews-new-unavailable-schedule-modal--margin-right-4"
          data-test="unavailable-schedule-modal-prev-button"
          variant="tertiary"
          color="blue"
          @click="$emit('close')"
        >
          {{ $t(`${langPath}.prevButton`) }}
        </ev-button>
        <ev-button
          data-test="unavailable-schedule-modal-next-button"
          @click="$emit('next')"
        >
          {{ $t(`${langPath}.nextButton`) }}
        </ev-button>
      </section>
    </template>
  </app-modal>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'

export default {
  name: 'InterviewsNewUnavailableScheduleModal',
  components: {
    EvButton
  },
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

<style lang="scss">
.interviews-new-unavailable-schedule-modal {
  &__header {
    @extend %h6;
  }

  &__body {
    @extend %body-text1;
  }

  &__footer {
    @include margin(top, 4);

    display: flex;
    justify-content: space-between;

    @include breakpoint(md) {
      justify-content: flex-end;
    }
  }

  &--margin-right-4 {
    @include margin(right, 4);
  }

  &--margin-top-4 {
    @include margin(top, 4);
  }
}
</style>
