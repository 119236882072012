export default {
  methods: {
    toIsoDate (date) {
      if (!date) { return null }

      return this.$moment(date).format('YYYY-MM-DD')
    },
    toISODatetime (date) {
      if (!date) { return null }

      return this.$moment(date).toISOString()
    },
    toHour (date) {
      if (!date) { return null }

      return this.$moment(date).format('HH:mm')
    },
    hourToMinutes (hour) {
      return this.$moment.duration(hour).asMinutes()
    },
    minutesToHour (minutes) {
      return this.toHour(
        this.$moment().startOf('day').add(minutes, 'minutes')
      )
    }
  }
}
