import dateFormatter from '@/helpers/date-formatter.js'

const defaultOptions = {
  fullWidth: false,
  withButtons: true,
  color: 'primary',
  headerColor: 'secondary',
  minDate: dateFormatter.todayISOStr(),
  minTime: '07:00',
  maxTime: '22:00'
}

export default {
  default: defaultOptions,
  startDate: {
    ...defaultOptions,
    maxTime: '21:30'
  },
  endDate: {
    ...defaultOptions,
    minTime: '07:30'
  }
}
