export default {
  methods: {
    isMobile () {
      return (
        /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      )
    }
  }
}
