<template>
  <app-sidebar-container class="interviews-new__calendar-page" size="full">
    <interviews-new-sidebar
      v-if="!isMobile() && applicant.candidate"
      slot="sidebar"
      :applicant-id="applicant.id"
      :date.sync="selectedDate"
      @update:date="getCalendarsAndEvents"
    />
    <app-loader
      v-show="isFetchingSchedule"
      data-test="interviews-new-loader"
      :message="$t(`${langPath}.loaderMessage`)"
      class="interviews-new__loader"
    />
    <app-calendar
      v-show="!isFetchingSchedule"
      v-model="selectedDate"
      class="interviews-new__calendar"
      data-test="app-calendar"
      :class="calendarClasses"
      :calendars="calendars"
      :events="events"
      @input="getCalendarsAndEvents"
      @click="handleClick"
      @create="handleSaveEvent"
      @update="handleSaveEvent"
    />
    <app-fab
      ref="fab"
      v-model="openedFab"
      only-mobile
      :icon="fabIcon"
    >
      <app-fab-bullet
        :label="$t(`${langPath}.scheduleInterview`)"
        :icon="IcnAddEvent"
        data-test="bullet-schedule"
        @click="handleScheduleModal"
      />
    </app-fab>
    <interviews-new-schedule-modal
      v-if="openedScheduleModal"
      :interview="draftInterview"
      @close="toggleScheduleModal"
      @success="handleSuccessfulSchedule"
    />
    <interviews-new-unavailable-schedule-modal
      v-if="openedUnavailableModal"
      @next="toggleScheduleModal"
      @close="toggleUnavailableModal"
    />
  </app-sidebar-container>
</template>

<script>
import InterviewsNewSidebar
  from './components/InterviewsNewSidebar'
import InterviewsNewScheduleModal
  from './components/InterviewsNewScheduleModal'
import InterviewsNewUnavailableScheduleModal
  from './components/InterviewsNewUnavailableScheduleModal'
import { createNamespacedHelpers } from 'vuex'
import { CANDIDATES, CURRENT_USER, EXTERNAL_CALENDARS, INTERVIEWS, APPLICANTS }
  from '@/store/namespaces'
import dateFormatter
  from '@/helpers/date-formatter.js'
import routerHelper from '@/helpers/router'
import isMobile
  from '@/mixins/is-mobile.js'
import { styleTokens, IcnAddEvent, IcnImport, IcnAdd } from '@revelotech/everest'
import repository from '@/repository/external-calendars'

const currentUserHelper = createNamespacedHelpers(CURRENT_USER)
const interviewsHelper = createNamespacedHelpers(INTERVIEWS)
const candidatesHelper = createNamespacedHelpers(CANDIDATES)
const calendarsHelper = createNamespacedHelpers(EXTERNAL_CALENDARS)
const applicantsHelper = createNamespacedHelpers(APPLICANTS)

export default {
  name: 'InterviewsNew',
  components: {
    InterviewsNewSidebar,
    InterviewsNewScheduleModal,
    InterviewsNewUnavailableScheduleModal
  },
  mixins: [isMobile],
  data () {
    return {
      langPath: __langpath,
      IcnAddEvent,
      IcnImport,
      fabIcon: IcnAdd,
      openedFab: false,
      openedScheduleModal: false,
      openedUnavailableModal: false,
      selectedDate: dateFormatter.todayISOStr(),
      draftInterview: {}
    }
  },
  computed: {
    ...applicantsHelper.mapState(['applicant']),
    ...currentUserHelper.mapState(['currentUser']),
    ...candidatesHelper.mapState([
      'unavailabilities',
      'isFetchingUnavailabilities'
    ]),
    ...calendarsHelper.mapState([
      'externalCalendars',
      'externalEvents',
      'isFetchingExternalEvents'
    ]),
    ...interviewsHelper.mapState(['interviews']),
    unavailabilitiesSchedule () {
      return this.unavailabilities.unavailabilitiesSchedule || []
    },
    unavailabilitiesCalendar () {
      return {
        id: 'unavailabilitiesCalendar',
        name: this.$t(`${this.langPath}.unavailableTimeName`),
        color: styleTokens.gray40,
        bgColor: styleTokens.gray5,
        borderColor: styleTokens.gray5
      }
    },
    unavailabilitiesEvents () {
      return this.unavailabilitiesSchedule.map((unavailability) => ({
        title: this.$t(`${this.langPath}.unavailableTimeTitle`),
        start: `${unavailability.date}T${unavailability.startTime}`,
        end: `${unavailability.date}T${unavailability.endTime}`,
        isReadOnly: true,
        calendarId: this.unavailabilitiesCalendar.id
      }))
    },
    calendarClasses () {
      return {
        'is-rescheduling': this.isRescheduling
      }
    },
    interviewsCalendar () {
      return {
        id: 'interviewsCalendar',
        name: this.$t(`${this.langPath}.defaultCalendarName`),
        color: styleTokens.white,
        bgColor: styleTokens.red,
        borderColor: styleTokens.red
      }
    },
    interviewsEvents () {
      return this.interviews?.map((interview) => ({
        id: interview.id,
        title: `${this.interviewsTitlePrefix} ${interview.candidateName}`,
        start: interview.scheduledStartAt,
        end: interview.scheduledEndAt,
        calendarId: this.interviewsCalendar.id
      })) || []
    },
    externalCalendar () {
      return {
        id: 'externalCalendar',
        name: this.$t(`${this.langPath}.externalCalendarTitle`),
        color: styleTokens.blueDark,
        bgColor: styleTokens.blueLight,
        borderColor: styleTokens.blueLight
      }
    },
    hasCalendars () {
      return this.externalCalendars.length
    },
    externalCalendarEvents () {
      return this.externalEvents.map((calendarEvent) => ({
        title: calendarEvent.title || this.busyTitle,
        start: calendarEvent.start,
        end: calendarEvent.end,
        isReadOnly: true,
        calendarId: this.externalCalendar.id
      }))
    },
    calendars () {
      return [
        this.unavailabilitiesCalendar,
        this.interviewsCalendar,
        this.externalCalendar
      ]
    },
    events () {
      return [
        ...this.unavailabilitiesEvents,
        ...this.interviewsEvents,
        ...(this.hasCalendars ? this.externalCalendarEvents : [])
      ]
    },
    isFetchingSchedule () {
      return this.isFetchingUnavailabilities || this.isFetchingInterviews ||
              this.isFetchingExternalEvents
    },
    isRescheduling () {
      return Boolean(this.selectedInterviewToReschedule)
    },
    selectedInterviewToReschedule () {
      const rescheduleInterviewId = this.$route.query.reschedule
      return this.interviews?.find(
        interview => interview.id === rescheduleInterviewId
      )
    },
    interviewsTitlePrefix () {
      return this.$t(`${this.langPath}.interviewsTitlePrefix`)
    },
    busyTitle () {
      return this.$t(`${this.langPath}.busyTitle`)
    }
  },
  watch: {
    isRescheduling: {
      handler () {
        if (this.isRescheduling) {
          this.draftInterview = { ...this.selectedInterviewToReschedule }

          this.toggleScheduleModal()
        }
      },
      immediate: true
    }
  },
  async created () {
    this.selectedDate = this.$route.query?.interviewDate || dateFormatter.todayISOStr()
    if (!this.currentUser?.id) await this.getCurrentUser()

    this.setApplicantId(this.$route.params.applicantId)
    await Promise.all([
      this.getApplicant(this.$route.params.applicantId),
      this.getExternalCalendars()
    ])
    this.getCalendarsAndEvents()
  },
  methods: {
    ...applicantsHelper.mapActions([
      'getApplicant',
      'setApplicantId'
    ]),
    ...currentUserHelper.mapActions(['getCurrentUser']),
    ...candidatesHelper.mapActions(['getUnavailabilities']),
    ...interviewsHelper.mapActions(['getInterviews']),
    ...calendarsHelper.mapActions([
      'getExternalCalendars',
      'getExternalEvents',
      'setSelectedCalendars'
    ]),
    getCalendarsAndEvents () {
      this.getInterviews(this.selectedDate)

      if (this.candidateFromMarketplace()) {
        this.getUnavailabilities({
          candidateId: this.applicant.candidate.externalId,
          startDateISO: this.selectedDate
        })
      }

      if (this.externalCalendars.length) {
        this.getExternalEvents(this.selectedDate)
      }
    },
    candidateFromMarketplace () {
      return this.applicant?.candidate?.source === 'marketplace'
    },
    handleSaveEvent (calendarEvent) {
      this.setDraftInterview(calendarEvent)

      if (this.hasUnavailabilitiyOn(this.draftInterview)) {
        this.toggleUnavailableModal()
      } else {
        this.toggleScheduleModal()
      }
    },
    handleClick (calendarEvent) {
      this.setDraftInterview(calendarEvent)

      if (calendarEvent.calendarId === this.unavailabilitiesCalendar.id) {
        this.toggleUnavailableModal()
      } else if (calendarEvent.calendarId === this.interviewsCalendar.id) {
        this.toggleScheduleModal()
      }
    },
    hasUnavailabilitiyOn ({ scheduledStartAt, scheduledEndAt }) {
      return this.unavailabilitiesSchedule.some(({
        date, startTime, endTime
      }) => {
        const start = this.$moment(`${date} ${startTime}`).toISOString()
        const end = this.$moment(`${date} ${endTime}`).toISOString()

        return scheduledStartAt < end && scheduledEndAt > start
      })
    },
    setDraftInterview (calendarEvent) {
      const baseInterview = this.isRescheduling
        ? this.selectedInterviewToReschedule
        : this.interviews?.find(interview => interview.id === calendarEvent.id)

      this.draftInterview = {
        ...baseInterview,
        scheduledStartAt: calendarEvent.start,
        scheduledEndAt: calendarEvent.end
      }
    },
    handleScheduleModal () {
      this.draftInterview = {}

      this.toggleScheduleModal()
    },
    toggleScheduleModal () {
      const openedScheduleModal = !this.openedScheduleModal
      this.resetModals()
      this.openedScheduleModal = openedScheduleModal
    },
    toggleUnavailableModal () {
      const openedUnavailableModal = !this.openedUnavailableModal
      this.resetModals()
      this.openedUnavailableModal = openedUnavailableModal
    },
    handleSuccessfulSchedule () {
      if (this.$route.query.redirect_url) {
        routerHelper.redirectToOutside(this.$route.query.redirect_url)
      }
    },
    connectExternalCalendars () {
      repository.getAuthUrl()
    },
    resetModals () {
      this.openedScheduleModal = false
      this.openedUnavailableModal = false
      this.openedFab = false
    }
  }
}
</script>

<style lang="scss">
.interviews-new {
  &__loader {
    margin-top: 25vh;
  }

  &__calendar-page {
    height: auto;
    min-height: 100%;
    padding-top: $app-header-height;

    > * {
      height: auto;
    }
  }

  &__calendar {
    &.is-rescheduling {
      .tui-full-calendar-time-schedule {
        &[data-calendar-id="interviewsCalendar"] {
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
        }

        &:not([data-calendar-id="interviewsCalendar"]) {
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>
